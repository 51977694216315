import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@patomation/react-ui-components'
import A from './A'

const ButtonLink = ({children, center, to, kind, background, color}) => {
  return (
    <A to={to}>
      <Button
        background={background || '#86AC41'} //green
        color={ color | '#324851' } //dark blue green
        kind={kind}
        center={center ? true : false}>
        {children}
      </Button>
    </A>
  )
}
ButtonLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  center: PropTypes.string,
  to: PropTypes.string,
  kind: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string
}

export default ButtonLink
