import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Review from './Review.js'
import { Grid } from '@patomation/react-ui-components'
import find from '@patomation/find'

const ReviewList = () => {

  const data = useStaticQuery(graphql`
    query Reviews {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/reviews/"}}) {
        edges {
          node {
            frontmatter {
              reviews {
                name
                date
                stars
                text
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Grid gap='1rem'>
      {find('reviews', data).map( (review, index) =>

        <Review
          key={review.name}
          id={`review_${index}`}
          {...review} />

      )}
    </Grid>
  )

}

export default ReviewList
