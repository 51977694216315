import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import { Gutter } from '@patomation/react-ui-components'
import Stars from './Stars.js'

const Review = ({
  id, stars, name, date, text, link, excerpt
}) =>

<div id={id}>

  <Stars total={stars}/>

  <div>
    {name} <Gutter vertical/> {date}
  </div>

  <div>
    { excerpt ? `${text.substring(0,excerpt)}...` : text }
    { link ? <Link to={link} style={{color:'#86AC41'}}>Read Full Review</Link> : null }
  </div>


</div>

Review.propTypes = {
  id: PropTypes.string,
  stars: PropTypes.number,
  name: PropTypes.string,
  date: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  excerpt: PropTypes.number
}

export default Review
