import React from 'react'
import rehypeReact from "rehype-react"

import { Grid, ImageGrid, Gutter, Section } from '@patomation/react-ui-components'
import Review from '../components/Review'
import ReviewSlider from '../components/ReviewSlider'
import Team from '../components/Team'
import PackageList from '../components/PackageList'
import ReviewList from '../components/ReviewList'
import ButtonLink from '../components/ButtonLink'
import A from '../components/A'

//Allows us to render componts in Markdown files
const renderAst = new rehypeReact({
  createElement: React.createElement,
  Fragment: React.Fragment, //Removes wrapping div
  components: {
    'a': A,
    'p': ({children}) => {
      //Only wrap text in p tags
      if(typeof children[0] === 'string') {
        return <p style={{/* paddingBottom: '1rem' */}}>{children}</p>
      //Don't wrap other things in p tags
      } else {
        return <>{children}</>
      }
    },
    'h1': ({children}) => <h1 style={{/*paddingBottom: '1rem'*/}}>{children}</h1>,
    'h2': ({children}) => <h2 style={{/*paddingBottom: '1rem'*/}}>{children}</h2>,
    'h3': ({children}) => <h3 style={{/*paddingBottom: '1rem'*/}}>{children}</h3>,
    'h4': ({children}) => <h4 style={{/*paddingBottom: '1rem'*/}}>{children}</h4>,
    'h5': ({children}) => <h5 style={{/*paddingBottom: '1rem'*/}}>{children}</h5>,
    'h6': ({children}) => <h6 style={{/*paddingBottom: '1rem'*/}}>{children}</h6>,
    'grid': ({col, row, gap, children}) =>
      <Grid
        col={parseInt(col)}
        row={parseInt(row)}
        gap={gap}>
        {children}
      </Grid>,
    'image-grid': ({col, row, gap, children}) =>
      <ImageGrid
        col={parseInt(col)}
        row={parseInt(row)}
        gap={gap}>
        {children}
      </ImageGrid>,
    'gutter': Gutter,
    'button': ButtonLink,
    'review': Review,
    'review-slider': ReviewSlider,
    'team': Team,
    'package-list': PackageList,
    'review-list': ReviewList,
    'section': Section
  }
}).Compiler

export default renderAst
