import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import TeamMember from '../components/TeamMember.js'
import { Grid } from '@patomation/react-ui-components'
import find from '@patomation/find'

const Team = () => {
  const data = useStaticQuery(graphql`
    query TeamMembers {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/team/"}}) {
        edges {
          node {
            fields{
              slug
            }
            frontmatter {
              name
              position
              image
            }
          }
        }
      }
    }
  `)

  return (
    <Grid col={3} gap='1rem' style={{
      paddingTop: '1rem',
      paddingBottom: '1rem',
    }}>
      {find('edges', data).map( (node, index) =>
        <TeamMember
          key={`team_member_${index}`}
          {...find('frontmatter', node)}
          link={find('slug', node)} />
      )}
    </Grid>
  )
}
export default Team
