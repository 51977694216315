import React from 'react'
import PropTypes from 'prop-types'
import { Image } from '@patomation/react-ui-components'
import { Link } from 'gatsby'

const TeamMember = ({
  name,
  position,
  image,
  link,
}) =>

<Link to={link}>
  <Image src={image} alt={name} circle maxWidth='200px' />
  <h3 style={{textAlign: 'center'}}>{name}</h3>
  <div style={{textAlign: 'center'}}>{position}</div>
</Link>

TeamMember.propTypes = {
  name: PropTypes.string,
  position: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string
}

export default TeamMember
