import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import { graphql, navigate, Link } from 'gatsby'
import moment from 'moment'
import find from '@patomation/find'
import Layout from '../components/Layout'
import renderAst from './_renderAst.js'
import Calendar from '../components/Calendar'
import {
  Grid, Image, Hr, Button, Panel, Gutter,
  Modal, Carousel, Clickable, IconButton, Input, Select, List,
  Section, Row
} from '@patomation/react-ui-components'
import useGlobal from '../hooks/useGlobal'
import SEO from '../components/seo'
import queryParam from '../modules/queryParams2'

const Package = ({
  data,
  pageContext
}) => {

  const queryParams = queryParam()
  const [ showGalery, setShowGalery ] = useState(false)
  const [ selectedDay, setSelectedDay ] = useState(queryParams.date ? moment(queryParams.date) : null)
  const [ globalState, setGlobalState ] = useGlobal({cart:[], travelers: 1}) //travelers needs to be a string

  useEffect(()=>{
      // set global state travelers if query param | override local state
      if(queryParams.travelers) {
        setGlobalState({ travelers: queryParams.travelers })
      }
  }, [])

  const basePrice = find('base_price', data)
  const personPrice = find('person_price', data)

  const addToCart = (day) => {

      let cart = [ ...globalState.cart ]
      cart.push({
        title: find('title', data),
        date: moment(day || selectedDay).format('YYYY-MM-DD'),
        basePrice,
        personPrice,
        travelers: globalState.travelers,
        slug: pageContext.slug
      })
      setGlobalState({cart:cart})
      navigate('/cart')

  }


  return data ?
  <Layout
    belowMain={
      <Section
        className='package__cta-bar'
        background='#324851'
        color='#ffffff'
        style={{
          // textAlign: 'center',
          paddingTop: '1rem',
          paddingBottom: '1rem'
        }}
        maxWidth='800px'>
        <Row>
          <Image
            src={find('image', data)}
            width='50px'
            circle />

          <Gutter vertical/>

          <h3 style={{
            flexGrow:1
          }}>{find('title', data)}</h3>

          <Button
            title='Book This Tour'
            background='#86AC41'
            color='#324851'
            onClick={() => {
              //Add to cart
              addToCart(!selectedDay ? moment().add(1,'day') : null)
            }}/>


        </Row>
      </Section>
    }
  >

  <SEO title={find('title', data)} description={find('description', data)}/>

  <Modal
    show={showGalery}
    maxWidth='800px'
    color='#ffffff'
    onClose={() => {setShowGalery(false)}}>
    <Carousel autoplay={false}>
      {
        [ ...[find('image', data) || null],
          ...find('collection', data)  || []
        ].map( (image, index) => {
          return <Image rectangle key={`carousel_image_${index}`} src={image} />
        })
      }
    </Carousel>
  </Modal>

  <section style={{paddingBottom: '3rem'}}>
  <Grid className='package' col={3} gap='1rem'>

    <div
      id='printable'
      className='package__content'
      style={{
        gridColumn: 'auto / span 2'
      }}>
      <Grid gap='1rem'>
        <h1>{find('title', data)}</h1>

        <Hr/>

        <p>{ find('description', data) }</p>

        { renderAst( find('htmlAst', data) ) }

      </Grid>
    </div>

    <div
      className='package__sidebar'
      style={{textAlign:'center'}}>

      <Clickable
        className='package__previewimage'
        onClick={()=>{setShowGalery(true)}}>
        <Image rectangle src={find('image', data)}/>
        <Gutter />
          <IconButton name='camera_alt' title='View Galery' />
      </Clickable>

      <Carousel className='package__carousel' autoplay={true}>
        {
          [ ...[find('image', data) || null],
            ...find('collection', data)  || []
          ].map( (image, index) => {
            return <Image rectangle key={`carousel_image_${index}`} src={image} />
          })
        }
      </Carousel>

      <Gutter />

      <Panel
        className='package__cta'
        header={<h2>Book This Tour</h2>}>

        <Select
          value={globalState.travelers}
          selectStyle={{
            fontWeight: 'bold'
          }}
          optionStyle={{
            fontWeight: 'bold'
          }}
          onChange={ e => {
            setGlobalState({travelers: parseInt(e.target.value)})
          }}
          options={{
            '1': `${basePrice + (personPrice * 1)} USD for 1 person`,
            '2': `${basePrice + (personPrice * 2)} USD for 2 people`,
            '3': `${basePrice + (personPrice * 3)} USD for 3 people`,
            '4': `${basePrice + (personPrice * 4)} USD for 4 people`,
            '5': `${basePrice + (personPrice * 5)} USD for 5 people`,
            '6': `${basePrice + (personPrice * 6)} USD for 6 people`,
            '7': `${basePrice + (personPrice * 7)} USD for 7 people`,
            '8': `${basePrice + (personPrice * 8)} USD for 8 people`,
            '9': `${basePrice + (personPrice * 9)} USD for 9 people`,
            '10': `${basePrice + (personPrice * 10)} USD for 10 people`,
            '11': `${basePrice + (personPrice * 11)} USD for 11 people`,
            '12': `${basePrice + (personPrice * 12)} USD for 12 people`,
          }}/>

          <Gutter />



        <Calendar
          initialSelectedDay={queryParams.date}
          selectedColor='#86AC41'
          onClick={({day}) => {
            setSelectedDay(day)
        }}/><Gutter/>
        <Button title="Book Now"
          disabled={selectedDay ? false : true}
          background='#86AC41' //green
          color='#324851' //dark blue green
          onClick={addToCart} />

      </Panel>

    </div>

  </Grid>

  </section>
  </Layout>
: null
}

Package.propTypes = {
  data: PropTypes.object
}

export default Package;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      htmlAst
      frontmatter {
        title
        description
        hero_image
        image
        collection
        base_price
        person_price
      }
    }
  }
`
